import React, { useState, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectDraftById } from 'ducks/drafts2/selectors'
import { doUpsertReplyDraft } from 'ducks/drafts2/operations/doUpsertReplyDraft'
import cn from 'classnames'
import ListenToKeyboard from 'components/ListenToKeyboard'
import { ButtonWithDropdown } from 'shared/ui'
import { selectIsRequiredConversationFieldsFilled } from 'ducks/crm/contacts/selectors/customFields'
import DropdownSwitcher from './DropdownSwitcher'
import { baseButtonStyle, noteButtonStyle } from './styles'
import ButtonDisableWrapper from './ButtonDisableWrapper'

const Button = ({
  actionLabel,
  className,
  disabled = false,
  draftId,
  ticketId,
  isOpen,
  isNote,
  prefersOpen,
  dropdownVisible = true,
  onSendClick,
  onSendClosedClick,
  onSendOpenClick,
  onSnoozeOptionClick,
  validateCustomFields,
}) => {
  const isCustomFieldsValid =
    useSelector(selectIsRequiredConversationFieldsFilled) ||
    !validateCustomFields

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const containerRef = useRef(null)

  const dispatch = useDispatch()
  const draft = useSelector(state => selectDraftById(state, draftId))

  const resetAutomaticActionStatus = useCallback(
    async () => {
      const automaticActions = (draft?.automaticActions || []).filter(
        a => a.type !== 'status'
      )

      if (automaticActions.length > 0) {
        await dispatch(
          doUpsertReplyDraft(draft.id, 'reply', ticketId, null, {
            automaticActions,
          })
        )
      }
    },
    [dispatch, draft, ticketId]
  )

  const getButtonText = useCallback(
    () => {
      if (isNote && actionLabel) {
        return actionLabel
      }

      if (draft && draft.automaticActions) {
        const stateAction = draft.automaticActions.find(
          a => a.type === 'status'
        )
        const snoozeAction = draft.automaticActions.find(
          a => a.type === 'snooze_until'
        )

        if (stateAction) {
          switch (stateAction.value) {
            case 'opened': {
              return `${actionLabel} & ${isOpen ? 'leave opened' : 'Open'}`
            }
            case 'closed': {
              return `${actionLabel} & ${isOpen ? 'leave closed' : 'Close'}`
            }
            case 'spam': {
              return `${actionLabel} & Spam`
            }
            default:
          }
        }

        if (snoozeAction) {
          return `${actionLabel} & Snooze`
        }
      }

      return `${actionLabel} & ${prefersOpen ? 'Open' : 'Close'}`
    },
    [actionLabel, draft, isNote, isOpen, prefersOpen]
  )

  const closeDropdown = useCallback(() => setDropdownOpen(false), [])
  const openDropdown = useCallback(
    () => {
      if (!disabled) setDropdownOpen(true)
    },
    [disabled]
  )

  const toggleDropdown = useCallback(
    () => {
      if (dropdownOpen) {
        closeDropdown()
      } else {
        openDropdown()
      }
    },
    [dropdownOpen, closeDropdown, openDropdown]
  )

  const handleDropdownClick = useCallback(
    e => {
      const clickedOption = e.target.closest('.item[role="option"]')
      const isSnoozeOption =
        clickedOption && /snooze/.test(clickedOption.textContent)

      if (clickedOption && isSnoozeOption) return false

      return toggleDropdown()
    },
    [toggleDropdown]
  )

  const handleSnoozeOptionClick = useCallback(
    async (snoozeUntil, evt) => {
      if (onSnoozeOptionClick) {
        await resetAutomaticActionStatus()
        await onSnoozeOptionClick(snoozeUntil, evt)
      }
      closeDropdown()
    },
    [onSnoozeOptionClick, resetAutomaticActionStatus, closeDropdown]
  )

  const handleSendClosedClick = useCallback(
    async () => {
      await resetAutomaticActionStatus()
      onSendClosedClick()
    },
    [onSendClosedClick, resetAutomaticActionStatus]
  )

  const handleSendOpenClick = useCallback(
    async () => {
      await resetAutomaticActionStatus()
      onSendOpenClick()
    },
    [onSendOpenClick, resetAutomaticActionStatus]
  )

  const handleButtonClick = useCallback(
    () => {
      if (disabled) return

      if (isNote && onSendClick) {
        onSendClick()
      } else if (prefersOpen) {
        onSendOpenClick()
      } else {
        onSendClosedClick()
      }

      closeDropdown()
    },
    [
      disabled,
      isNote,
      onSendClick,
      onSendClosedClick,
      onSendOpenClick,
      prefersOpen,
      closeDropdown,
    ]
  )

  return (
    <ButtonDisableWrapper
      disabled={disabled || !isCustomFieldsValid}
      css={[baseButtonStyle, isNote && noteButtonStyle]}
      className={className}
      ref={containerRef}
      tooltip={
        !isCustomFieldsValid ? 'Please complete required custom fields' : null
      }
    >
      <ListenToKeyboard onEscape={closeDropdown} enabled={dropdownOpen} />
      <ButtonWithDropdown
        className={cn({ 'without-dropdown': !dropdownVisible })}
        dropdown={
          dropdownVisible && (
            <DropdownSwitcher
              actionLabel={actionLabel}
              isOpen={isOpen}
              onBlur={closeDropdown}
              onClick={handleDropdownClick}
              onSendClosedClick={handleSendClosedClick}
              onSendOpenClick={handleSendOpenClick}
              onSnoozeOptionClick={handleSnoozeOptionClick}
              open={dropdownOpen}
              openOnFocus={false}
              prefersOpen={prefersOpen}
              isNote={isNote}
            />
          )
        }
        onClick={handleButtonClick}
      >
        {getButtonText()}
      </ButtonWithDropdown>
    </ButtonDisableWrapper>
  )
}

export default Button
