import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import {
  selectConversationEventGroupById,
  selectCurrentConversationById,
  selectMessageById,
} from 'ducks/tickets/selectors'
import Attachments from 'components/Attachments'
import { capture, record } from 'ducks/tracking/actions'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_CANNED_REPLIES_UPDATE } from 'ducks/drawers/types'
import { emptyObj } from 'util/objects'
import { isCustomerRole } from 'ducks/tickets/utils/message'
import { selectAttachmentsByMessageId } from 'ducks/tickets/selectors/selectAttachmentsByMessageId'
import Header from './Header'
import Body from './Body'
import TrackingDetails from './TrackingDetails'
import Addresses from './Addresses'

import Avatar from './Avatar'

const ExpandedMessageContent = ({
  eventGroupId,
  expandChangeset,
  showMeta,
  mailboxIndicatorNeeded,
  mailboxName,
  mailboxColor,
  isAgent,
}) => {
  const data = useSelector(
    state =>
      selectConversationEventGroupById(state, eventGroupId, null, true) ||
      emptyObj
  )
  const {
    summary: { id: messageId, isBodyEmpty, body },
    isForward,
    isNote,
    conversationId,
    isFirstMessage,
    actor,
  } = data
  const {
    id: actorId,
    name: actorName,
    role: actorRole,
    avatarUrl: actorAvatarUrl,
  } =
    actor || {}
  const { subject } = useSelector(
    state => selectCurrentConversationById(state, conversationId) || emptyObj
  )

  const change = useSelector(state =>
    selectMessageById(state, messageId, null, true)
  )
  const attachments = useSelector(state =>
    selectAttachmentsByMessageId(state, messageId)
  )
  const { parts } = change
  const [isAddressSectionExpanded, setIsAddressSectionExpanded] = useState(
    false
  )

  const { openDrawer: openUpdateDrawer } = useDrawer(
    DRAWER_TYPE_CANNED_REPLIES_UPDATE
  )

  const onAiOptionSelected = useCallback(
    item => {
      if (item.id === 'instantReply') {
        capture('ai instant_reply selected', {
          message_id: messageId,
        })
        record()
        openUpdateDrawer('newAi', {
          additionalProps: {
            aiProps: {
              messageBody: body,
              messageSubject: subject,
            },
          },
        })
      }
    },
    [openUpdateDrawer, messageId, body, subject]
  )

  const toggleExpandAddressSection = useCallback(
    e => {
      if (e && e.stopPropagation) {
        // prevent expand/collapse of changeset
        e.stopPropagation()
      }
      // can only expand once. until actual changeset is expanded/collapsed/re-rendered
      if (!isAddressSectionExpanded) {
        setIsAddressSectionExpanded(true)
      }
    },
    [isAddressSectionExpanded]
  )

  const isIncomingMessage = isCustomerRole(actorRole)

  return (
    <>
      <div className="top-side" onClick={expandChangeset}>
        <Avatar
          id={actorId}
          type={actorRole}
          name={actorName}
          avatarUrl={actorAvatarUrl}
        />
        <div className="header-container">
          <Header
            eventGroupId={eventGroupId}
            onAiOptionSelected={onAiOptionSelected}
            showAIInstantReplyButton={isAgent}
            showMeta={showMeta}
          />
          {!isNote &&
            mailboxIndicatorNeeded && (
              <div className="mailbox-indicator">
                <div
                  className="dot"
                  style={{ backgroundColor: mailboxColor }}
                />
                <div className="name">{mailboxName}</div>
              </div>
            )}
          <div
            className={cn('address-section', {
              expanded: isAddressSectionExpanded,
            })}
            onClick={toggleExpandAddressSection}
          >
            <Addresses
              change={change}
              isNote={isNote}
              mailboxIndicatorNeeded={mailboxIndicatorNeeded}
              expanded={isAddressSectionExpanded}
              isIncomingMessage={isIncomingMessage}
              isForward={isForward}
            />
          </div>
        </div>
      </div>
      <div className="middle-side">
        <Body
          parts={parts}
          isBodyEmpty={isBodyEmpty}
          isForward={isForward}
          isFirstMessage={isFirstMessage}
        />
      </div>
      <div className="bottom-side">
        <Attachments attachments={attachments} />
        {isFirstMessage && <TrackingDetails ticketId={conversationId} />}
      </div>
    </>
  )
}

export default ExpandedMessageContent
