import React, {
  useCallback,
  useEffect,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { selectIsPrintModeOn } from 'selectors/app'
import cn from 'classnames'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'

import { SUI } from 'shared/ui'
import ListenToKeyboard from 'components/ListenToKeyboard'
import ScrollerContext from 'components/Scroller/Context'
import { selectTicketEventGroupsByConversationId } from 'ducks/tickets/selectors/selectTicketEventGroupsByConversationId'
import { clearHash } from 'ducks/location/utils'
import usePrevious from 'util/hooks/usePrevious'
import AIMessage from './AIMessage'
import withMessageScroller from './withMessageScroller'
import styles from './styles.less'
import EventGroup from './EventGroup'

function cleanChangesetId(hash) {
  const changesetId = hash?.replace(/^#(changeset-)?/, '')
  return changesetId || undefined
}

const Changesets = ({ ticketId, onScrollToChangeset }) => {
  const [groupIdExpandedState, setGroupIdExpandedState] = useState({})
  const [hasScrolled, setHasScrolled] = useState(false)
  const eventGroups = useSelector(state =>
    selectTicketEventGroupsByConversationId(state, ticketId)
  )

  const eventGroupIdByChangesetId = useMemo(
    () =>
      eventGroups.reduce((acc, eg) => {
        // eslint-disable-next-line no-param-reassign
        acc[eg.changesetId] = eg.id
        return acc
      }, {}),
    [eventGroups]
  )

  // Get the last message id excluding nodes. The idea here is that
  // the last note and last normal message should both be expanded
  const lastEventGroupId = useMemo(
    () => {
      return eventGroups.filter(eg => eg.isNote === false).pop()?.id
    },
    [eventGroups]
  )

  const { getScrollerAPI } = useContext(ScrollerContext)

  const isPrintModeOn = useSelector(selectIsPrintModeOn)
  const wasPrintModeOn = usePrevious(isPrintModeOn)
  const is3ColumnView = useSelector(selectPrefersClassicView)
  const [selectedChangesetId] = useState(cleanChangesetId(window.location.hash))

  const toggleEventGroupExpanded = useCallback(
    (_e, eventGroupId) => {
      setGroupIdExpandedState(currentGroupIdExpandedState => ({
        ...currentGroupIdExpandedState,
        [eventGroupId]: !currentGroupIdExpandedState[eventGroupId],
      }))
      clearHash()
    },
    [setGroupIdExpandedState]
  )

  const setEventGroupExpanded = useCallback(
    (_e, eventGroupId) => {
      setGroupIdExpandedState(currentGroupIdExpandedState => ({
        ...currentGroupIdExpandedState,
        [eventGroupId]: true,
      }))
      clearHash()
    },
    [setGroupIdExpandedState]
  )

  const handleEventGroupOnLoad = useCallback(
    (eventGroupId, changesetId) => {
      if (
        !hasScrolled &&
        (selectedChangesetId === changesetId ||
          (!selectedChangesetId && eventGroupId === lastEventGroupId))
      ) {
        setHasScrolled(true)
        onScrollToChangeset(changesetId)
      }
    },
    [onScrollToChangeset, selectedChangesetId, lastEventGroupId, hasScrolled]
  )

  useEffect(
    () => {
      setGroupIdExpandedState(currentGroupIdExpandedState => {
        const newGroupIdExpandedState = { ...currentGroupIdExpandedState }
        const mustUpdate = isPrintModeOn || wasPrintModeOn
        eventGroups.forEach((eventGroup, index) => {
          const { id, changesetId } = eventGroup
          const isLast = index >= eventGroups.length - 1
          const isLastMessage = id === lastEventGroupId
          const isRefrencedInUrl = selectedChangesetId === changesetId
          const isExpanded =
            isPrintModeOn ||
            isRefrencedInUrl ||
            (!isRefrencedInUrl && (isLast || isLastMessage))

          newGroupIdExpandedState[id] =
            mustUpdate || newGroupIdExpandedState[id] === undefined
              ? isExpanded
              : newGroupIdExpandedState[id]
        })
        return newGroupIdExpandedState
      })
    },
    [
      eventGroups,
      isPrintModeOn,
      wasPrintModeOn,
      selectedChangesetId,
      lastEventGroupId,
      setGroupIdExpandedState,
    ]
  )

  useEffect(
    () => {
      // Define the event listener function
      const handleHashChange = () => {
        const changesetId = cleanChangesetId(window.location.hash)

        const eventGroupId = eventGroupIdByChangesetId[changesetId]
        if (!eventGroupId) return

        setEventGroupExpanded(null, eventGroupId)
        onScrollToChangeset(changesetId)
      }

      // Add the event listener
      window.addEventListener('hashchange', handleHashChange)

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('hashchange', handleHashChange)
      }
    },
    [setEventGroupExpanded, onScrollToChangeset, eventGroupIdByChangesetId]
  )

  const changeAll = useCallback(
    isExpanded => {
      setGroupIdExpandedState(currentGroupIdExpandedState => {
        const newGroupIdExpandedState = { ...currentGroupIdExpandedState }
        Object.keys(newGroupIdExpandedState).forEach(
          k => (newGroupIdExpandedState[k] = isExpanded)
        )
        return newGroupIdExpandedState
      })
    },
    [setGroupIdExpandedState]
  )

  const collapseAll = useCallback(
    () => {
      changeAll(false)
    },
    [changeAll]
  )

  const expandAll = useCallback(
    () => {
      changeAll(true)
    },
    [changeAll]
  )

  const handleOnLoading = useCallback(
    () => {
      getScrollerAPI().scrollToBottom()
    },
    [getScrollerAPI]
  )

  return (
    <SUI>
      <div
        className={cn(styles.changesetsContainer, {
          [styles['changesetsContainer-3-column-view']]: is3ColumnView,
        })}
      >
        <ListenToKeyboard
          onSemicolon={expandAll}
          onSemicolonFirefox={expandAll}
          onShiftSemicolon={collapseAll}
          onShiftSemicolonFirefox={collapseAll}
          disableForInput
        />
        {eventGroups.map(eventGroup => {
          const { id } = eventGroup
          return (
            <EventGroup
              key={id}
              eventGroupId={id}
              ticketId={ticketId}
              expanded={groupIdExpandedState[id]}
              onClick={toggleEventGroupExpanded}
              onLoad={handleEventGroupOnLoad}
            />
          )
        })}
        <AIMessage
          onLoading={getScrollerAPI() ? handleOnLoading : null}
          className="grui m-5"
        />
      </div>
    </SUI>
  )
}

export default withMessageScroller(Changesets)
