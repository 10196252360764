import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import { selectIsMailboxIndicatorNeeded } from 'selectors/currentChangesets/selectIsMailboxIndicatorNeeded'
import { selectCurrentTicketMailbox } from 'selectors/tickets'

import { selectConversationEventGroupById } from 'ducks/tickets/selectors'
import { emptyObj } from 'util/objects'

import ReplyNoteEditForm from 'components/App/DesktopView/CommunicationForms/Reply/NoteEdit'
import { isOutsideCommunication } from 'ducks/tickets/utils/message'
import SubsequentActions from './SubsequentActions'
import ExpandedMessageContent from './ExpandedMessageContent'
import ExpandedNoteContent from './ExpandedNoteContent'

const ExpandedMessage = ({ eventGroupId, onClick, ticketId }) => {
  const {
    changesetId,
    summary: { id: messageId },
    isForward,
    isNote,
    isFirstMessage,
    actor: { role: actorRole, __typename: actorType } = emptyObj,
  } = useSelector(
    state =>
      selectConversationEventGroupById(state, eventGroupId, null, true) ||
      emptyObj
  )

  const { name, color } = useSelector(selectCurrentTicketMailbox) || emptyObj
  const mailboxIndicatorNeeded = useSelector(selectIsMailboxIndicatorNeeded)
  const mailboxName = mailboxIndicatorNeeded ? name : null
  const mailboxColor = mailboxIndicatorNeeded ? color : null
  const [isEdit, setIsEdit] = useState(false)
  const [showMeta, setShowMeta] = useState(true)
  const isOutside = isOutsideCommunication(isForward, actorRole)

  const onEditClick = useCallback(
    e => {
      e.stopPropagation()
      setIsEdit(true)
    },
    [setIsEdit]
  )

  const onCancelEdit = useCallback(
    () => {
      setIsEdit(false)
    },
    [setIsEdit]
  )

  const onMouseOver = useCallback(
    () => {
      if (showMeta) {
        setShowMeta(false)
      }
    },
    [showMeta, setShowMeta]
  )

  const showActionHeader = useCallback(
    () => {
      setShowMeta(false)
    },
    [setShowMeta]
  )

  const showMetaHeader = useCallback(
    () => {
      setShowMeta(true)
    },
    [setShowMeta]
  )

  if (isEdit) {
    return (
      <div
        id={`changeset-${changesetId}`}
        className={cn('changeset expanded', {
          forward: isOutside,
        })}
      >
        <ReplyNoteEditForm
          ticketId={ticketId}
          messageId={messageId}
          onCancelEdit={onCancelEdit}
        />
        <SubsequentActions eventGroupId={eventGroupId} />
      </div>
    )
  }

  return (
    <div
      id={`changeset-${changesetId}`}
      className={cn('changeset expanded', {
        forward: isOutside,
      })}
    >
      <div className="action-container">
        <div
          className={cn('action', {
            message: !isNote,
            note: isNote,
            'fullstack-noteAction': isNote,
            forward: isOutside,
          })}
          data-print-target="message-or-note"
          onMouseEnter={showActionHeader}
          onMouseLeave={showMetaHeader}
          onMouseOver={onMouseOver}
        >
          <div className="contents">
            {!isNote && (
              <ExpandedMessageContent
                eventGroupId={eventGroupId}
                expandChangeset={onClick}
                showMeta={showMeta}
                mailboxIndicatorNeeded={mailboxIndicatorNeeded}
                mailboxName={mailboxName}
                mailboxColor={mailboxColor}
                isAgent={actorType === 'Agent'}
              />
            )}
            {isNote && (
              <ExpandedNoteContent
                eventGroupId={eventGroupId}
                messageId={messageId}
                expandChangeset={onClick}
                ticketId={ticketId}
                isFirstMessage={isFirstMessage}
                showMeta={showMeta}
                onEditClick={onEditClick}
              />
            )}
          </div>
        </div>
      </div>
      <SubsequentActions eventGroupId={eventGroupId} />
    </div>
  )
}

export default ExpandedMessage
