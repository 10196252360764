import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { doFetchTicket } from 'ducks/tickets/actions/doFetchTicket'
import { MAILBOX_CHANNEL_TYPE } from 'ducks/folders/constants'
import TicketNotFound from 'subapps/ticketing/components/TicketInspector/TicketNotFound'
import TicketDeleted from 'subapps/ticketing/components/TicketInspector/TicketNotFound/TicketDeleted'
import NoAccess from 'subapps/ticketing/components/TicketInspector/NoAccess'
import { selectTicketRequestByConversationId } from 'ducks/tickets/selectors/selectTicketRequestByConversationId'
import { selectCurrentConversationById } from 'ducks/tickets/selectors'
import { selectSearchByQueryId } from 'ducks/searches/selectors'
import { selectCurrentQueryId } from 'ducks/searches/selectors/selectCurrentQueryId'
import { selectCurrentQuery } from 'ducks/searches/selectors/selectCurrentQuery'
import { doFetchTickets } from 'ducks/tickets/actions'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { doFetchFolderCounts } from 'ducks/searches/operations/doFetchFolderCounts'
import { doOpenTicketPage } from 'actions/pages'
import { getRawId } from 'util/globalId'
import { doRedirectToCollectionAndFolderById } from 'ducks/folders/operations/collections'
import {
  selectIsOnTicketCommentPage,
  selectIsOnTicketPage,
} from 'selectors/location'
import { isBlank } from 'util/strings'
import NormalState from './NormalState'
import EmptyState from './EmptyState'

const TicketInspector = props => {
  const { ticketId: conversationId } = props
  const dispatch = useDispatch()
  const queryId = useSelector(selectCurrentQueryId)
  const { channel = [] } = useSelector(selectCurrentQuery)
  const channelId = channel[0]
  const is3ColumnView = useSelector(selectPrefersClassicView)
  const { loaded: searchLoaded, loading: searchLoading } = useSelector(state =>
    selectSearchByQueryId(state, queryId)
  )
  const ticket = useSelector(state =>
    selectCurrentConversationById(state, conversationId)
  )
  const { error, loading, loaded, untried } = useSelector(state =>
    selectTicketRequestByConversationId(state, conversationId)
  )
  const isOnTicketPage = useSelector(selectIsOnTicketPage)
  const isTicketCommentPage = useSelector(selectIsOnTicketCommentPage)

  const noAccessError = useMemo(
    () => {
      return error?.errors?.find(e => e?.extensions?.code === 'UNAUTHORIZED')
    },
    [error]
  )

  const deletedError = useMemo(
    () => {
      return error?.errors?.find(
        e => e?.extensions?.code === 'RESOURCE_DELETED'
      )
    },
    [error]
  )

  const mergeError = useMemo(
    () => {
      return error?.errors?.find(e => e?.extensions?.code === 'RESOURCE_MERGED')
    },
    [error]
  )

  useEffect(
    () => {
      if (!loading && !loaded && !error && untried) {
        dispatch(
          doFetchTicket({
            conversationId,
            channelType: MAILBOX_CHANNEL_TYPE,
          })
        )
      }
    },
    [dispatch, conversationId, loaded, loading, error, untried]
  )

  useEffect(
    () => {
      // 1) When we're on 4 column view, the ticket list will handle loading its conversations
      // 2) We'll first wait for the individual ticket call to finish, then load
      //    the list information to make sure the 2 requests arent competing for bandwith
      if (
        is3ColumnView &&
        (error || loaded) &&
        !searchLoaded &&
        !searchLoading
      ) {
        // Fetch the conversation list specified in the url
        dispatch(doFetchTickets({ isReload: false, loadFirst: true }))

        // If the current conversation list is scoped to a mailbox, do another hit
        // so pull the global non-channel scoped counts
        if (channelId) {
          dispatch(
            doFetchFolderCounts({
              channelType: MAILBOX_CHANNEL_TYPE,
            })
          )
        }
        // Finally pull the counts for the current mailbox
        dispatch(
          doFetchFolderCounts({ channelId, channelType: MAILBOX_CHANNEL_TYPE })
        )
      }
    },
    [
      dispatch,
      searchLoaded,
      searchLoading,
      error,
      loaded,
      is3ColumnView,
      channelId,
    ]
  )

  useEffect(
    () => {
      if (isBlank(queryId) && (isOnTicketPage || isTicketCommentPage)) {
        dispatch(
          doRedirectToCollectionAndFolderById(null, null, {
            ignoreLast: false,
            preservePage: true,
          })
        )
      }
    },
    [dispatch, queryId, isOnTicketPage, isTicketCommentPage]
  )

  if (error) {
    // No access
    if (noAccessError) {
      return <NoAccess mailboxId={noAccessError.extensions.channel_id} />
    } else if (deletedError) {
      return (
        <TicketDeleted
          actorId={deletedError.extensions.actor_id}
          actorType={deletedError.extensions.actor_type}
        />
      )
    } else if (mergeError) {
      dispatch(
        doOpenTicketPage(getRawId(mergeError.extensions.new_conversation_id))
      )
      return <EmptyState />
    }
    // There is an error, but we dont know what is
    // Fallback to 404 for this case. We should probably create a generic
    // error state
    return <TicketNotFound />
  } else if (loaded && !ticket) {
    return <TicketNotFound />
  }

  return <NormalState ticketId={conversationId} />
}

export default TicketInspector
