import React from 'react'

import { shouldStartExpanded } from './util'
import QuotedBody from './QuotedBody'
import TextBody from './TextBody'

const Body = ({ parts, isBodyEmpty, isForward, isFirstMessage }) => {
  return (
    <>
      {isBodyEmpty && (
        <div className="empty-body">No content in this message</div>
      )}
      {!isBodyEmpty &&
        parts.map((part, index) => {
          if (part.type === 'QUOTED') {
            const startExpanded = shouldStartExpanded(
              isForward,
              isFirstMessage,
              parts,
              index
            )

            return (
              <QuotedBody
                // eslint-disable-next-line react/no-array-index-key
                key={`quoted-${index}`}
                text={part.content}
                collapsed={!startExpanded}
              />
            )
          }
          return (
            <TextBody
              // eslint-disable-next-line react/no-array-index-key
              key={`text-${index}`}
              text={part.content}
            />
          )
        })}
    </>
  )
}

export default Body
